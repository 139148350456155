import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';
import { pushParams, resetParams } from 'dpl/actions/query_params';
import {
  TESTIMONIAL_MODAL_PAGE,
  TESTIMONIAL_MODAL_ID,
  BREEDER_PROFILE_ID
} from 'dpl/constants/query_params';
import usePaginatedTestimonials from 'dpl/components/BreederProfile/hooks/usePaginatedTestimonials';
import LoadingWrapper from 'dpl/components/LoadingWrapper';
import BuyerDisplay from 'dpl/testimonials/components/BuyerDisplay';
import Testimonial from 'dpl/testimonials/components/Testimonial';
import Paginator from 'dpl/common/components/pagination/components/Paginator';
import { getDogAge } from 'dpl/testimonials/utils';
import { createBreederProfilePaginationAction } from 'dpl/containers/BreederProfile/Show/BreederProfilePhotoAlbumContainer';

const pageParamSelector = state =>
  state.queryParams[TESTIMONIAL_MODAL_PAGE]
    ? Number(state.queryParams[TESTIMONIAL_MODAL_PAGE])
    : null;

const testimonialTargetSelector = state =>
  state.queryParams[TESTIMONIAL_MODAL_ID] ?? null;

export function useTestimonialModal() {
  const dispatch = useDispatch();

  const setPage = (page, testimonialId) => {
    dispatch(
      pushParams(
        {
          [TESTIMONIAL_MODAL_PAGE]: page,
          [TESTIMONIAL_MODAL_ID]: testimonialId ?? undefined
        },
        { hash: true }
      )
    );
  };
  const open = (testimonialId = null) => setPage(1, testimonialId);
  const close = () => {
    dispatch(
      resetParams(
        TESTIMONIAL_MODAL_PAGE,
        TESTIMONIAL_MODAL_ID,
        BREEDER_PROFILE_ID,
        { hash: true }
      )
    );
  };

  const currentPage = useSelector(pageParamSelector);
  const testimonialTarget = useSelector(testimonialTargetSelector);

  return {
    open,
    close,
    currentPage,
    setPage,
    testimonialTarget
  };
}

const MAX_SHOWN = 10;

export default function BreederProfileTestimonialModal({ testimonialId }) {
  const { close, currentPage, setPage, testimonialTarget } =
    useTestimonialModal();
  const { isLoading, testimonials, meta } = usePaginatedTestimonials({
    perPage: MAX_SHOWN,
    pageNumber: currentPage,
    testimonialId
  });
  const dispatch = useDispatch();

  // Ensures the selected testimonial displays when the modal opens
  useEffect(() => {
    let animationFrame = null;
    function attemptScrollToElement(attemptCount) {
      const el = document.getElementById(
        `testimonial-modal-${testimonialTarget}`
      );

      el?.scrollIntoView();

      if (el || attemptCount >= 5) {
        dispatch(resetParams(TESTIMONIAL_MODAL_ID, { hash: true }));
      } else {
        animationFrame = window.requestAnimationFrame(() =>
          attemptScrollToElement(attemptCount + 1)
        );
      }
    }

    if (!isLoading && testimonialTarget) {
      animationFrame = window.requestAnimationFrame(() =>
        attemptScrollToElement(0)
      );
    }

    return () => {
      window.cancelAnimationFrame(animationFrame);
    };
  }, [testimonialTarget, isLoading, dispatch]);

  if (!currentPage) return null;

  return (
    <MiniOverlayModal
      onCloseClick={close}
      contentClassName="bg-white"
      maxWidth="1000px"
    >
      <div className="BreederProfileTestimonialModal br3 ph5 pv12 ph19-md pv18-md bg-white">
        <p className="b f6 mb10">Owner stories</p>
        <LoadingWrapper isLoading={isLoading}>
          {() => (
            <>
              {testimonials.map((testimonial, idx) => (
                <div
                  key={testimonial.id}
                  className={classNames('row', {
                    'bt b--lightest-gray pv6': idx > 0,
                    pb6: idx === 0
                  })}
                >
                  <div className="col-md-3 pb2 pb-md-0">
                    <BuyerDisplay
                      user={testimonial.buyer_profile}
                      displayName={testimonial.buyer_profile.name}
                      date={testimonial.submitted_at}
                      variant="stacked"
                    />
                  </div>
                  <div
                    className="col-md-9"
                    id={`testimonial-modal-${testimonial.id}`}
                  >
                    <Testimonial
                      text={testimonial.experience}
                      photographs={testimonial.photographs}
                      dogName={testimonial.puppy_name}
                      dogAge={
                        testimonial.puppy_birth_date
                          ? getDogAge(
                              testimonial.puppy_birth_date,
                              testimonial.submitted_at
                            )
                          : null
                      }
                      instagramHandle={testimonial.instagram}
                      onImageClick={id =>
                        dispatch(createBreederProfilePaginationAction(id))
                      }
                    />
                  </div>
                </div>
              ))}
              {meta.total_count > MAX_SHOWN && (
                <div className="pt8 bt b--lightest-gray">
                  <Paginator
                    currentPage={currentPage}
                    onPageClick={nextPage => setPage(nextPage)}
                    itemsPerPage={MAX_SHOWN}
                    totalItemCount={meta.total_count}
                  />
                </div>
              )}
            </>
          )}
        </LoadingWrapper>
      </div>
    </MiniOverlayModal>
  );
}

BreederProfileTestimonialModal.propTypes = {
  testimonialId: PropTypes.number
};

BreederProfileTestimonialModal.defaultProps = {
  testimonialId: null
};
