import { useSelector } from 'react-redux';

import {
  breederProfileBreederTestimonialIndexResourceDefinition,
  breederProfileBreederTestimonialShowResourceDefinition
} from 'dpl/shared/breeder/utils/queryDefinitions';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { BREEDER_PROFILE_ID } from 'dpl/constants/query_params';

function getResourceDefinition(
  perPage,
  pageNumber,
  testimonialId,
  breederProfileId
) {
  const enabled = Boolean(breederProfileId);

  if (testimonialId) {
    return {
      ...breederProfileBreederTestimonialShowResourceDefinition(
        breederProfileId,
        testimonialId
      ),
      enabled
    };
  }

  return {
    ...breederProfileBreederTestimonialIndexResourceDefinition(
      breederProfileId,
      { pageNumber, perPage }
    ),
    enabled
  };
}

export default function usePaginatedTestimonials({
  perPage,
  pageNumber,
  testimonialId,
  breederProfileId: breederId
}) {
  const breederProfileId = useSelector(
    ({ server, queryParams }) =>
      breederId ?? server.breederProfileId ?? queryParams[BREEDER_PROFILE_ID]
  );

  const resourceDefinition = getResourceDefinition(
    perPage,
    pageNumber,
    testimonialId,
    breederProfileId
  );

  const { isLoading, value } = useResourceQuery(resourceDefinition);
  const { data = [], meta = {} } = value ?? {};
  const testimonials = Array.isArray(data) ? data : [data];

  return {
    isLoading,
    testimonials,
    meta
  };
}
