import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isMobileUA, truncateText } from 'dpl/shared/utils';
import { getShortDateString } from 'dpl/shared/utils/date';
import UserProfilePhoto from 'dpl/components/UserProfilePhoto';
import Icon from 'dpl/common/components/Icon';
import { getDisplayNameFromCurrentUser } from 'dpl/testimonials/utils/index';

const DESKTOP_TRUNCATED_CHARACTER_LENGTH = '100';
const MOBILE_TRUNCATED_CHARACTER_LENGTH = '70';
const isMobile = isMobileUA();
const TRUNCATED_CHARACTER_LENGTH = isMobile
  ? MOBILE_TRUNCATED_CHARACTER_LENGTH
  : DESKTOP_TRUNCATED_CHARACTER_LENGTH;

export default function ImageCarouselImageVerifiedOwnerExpandedDetails({
  details
}) {
  const [expanded, setExpanded] = useState(false);

  const text = details.description || '';
  const truncatedContent = truncateText(text, TRUNCATED_CHARACTER_LENGTH);
  const showExpansionArrow = truncatedContent.length !== text.length;

  return (
    <div
      className={classnames(
        'ImageCarouselImageVerifiedOwnerExpandedDetails br1 bg-black-70 pt4 transition',
        { ImageCarouselImageVerifiedOwnerExpandedDetails__open: expanded }
      )}
    >
      <div className="mb3 flex justify-between">
        <div className="flex align-items-center">
          <div className="mr2 f0">
            <UserProfilePhoto user={details.user} diameter="40px" />
          </div>
          <div>
            <p className="fw-medium">
              {getDisplayNameFromCurrentUser(details.user)}
            </p>
            <p className="white-80">
              {getShortDateString(new Date(details.photograph_date), false)}
            </p>
          </div>
        </div>
        {text && showExpansionArrow && (
          <button
            type="button"
            onClick={() => setExpanded(previousExpanded => !previousExpanded)}
            className="br-100 ba b--white w2 h2 flex justify-center align-items-center"
          >
            <Icon name={expanded ? 'fat-arrow-down' : 'fat-arrow-up'} />
          </button>
        )}
      </div>
      <p>
        <span>{expanded ? text : truncatedContent}</span>
      </p>
    </div>
  );
}

ImageCarouselImageVerifiedOwnerExpandedDetails.propTypes = {
  details: PropTypes.shape({
    description: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    user: PropTypes.object,
    photograph_date: PropTypes.string
  }).isRequired
};
